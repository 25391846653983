<template>
	<Teleport to="#navbar">
    <div v-if="isActive" id="voucher-banner" :class="{'voucher-notification-banner d-flex justify-content-center align-items-center': true, [message_type]: true}">
      <div class="banner-message" v-html="compileMD()">
      </div>
    </div>
	</Teleport>
</template>

<script setup>
import { ref, toRefs, onMounted, computed, watch } from 'vue'
import { marked } from 'marked'
import { useMobileHelpers } from '../utils/composables/mobileHelpers.js'
import { useStoreVoucherHelper } from './composables/storeVoucherHelper.js'

// Props (defineProps)
const props = defineProps({
  voucher_origin : {
    type: String,
    required: false,
    default: 'plp_store'
  },
  run_validation:{
    type: Boolean,
    required: false,
    default: true
  }
})
// Emits (defineEmits)
// Reactive variables (inc composables)
const { voucher_origin, run_validation } = toRefs(props)
const { voucher, voucher_store, voucher_banner_active, isVoucherEmpty } = useStoreVoucherHelper(voucher_origin.value, run_validation.value)
const { isMobileSm } = useMobileHelpers()
const message_type = ref(undefined)

// Computed
const isInGifts = computed (()=>{
  return window.location.pathname.includes('/gifts')
})
const isInAccount = computed (()=>{
  return window.location.pathname.includes('/account') || window.location.pathname.includes('/reset-password') ||  window.location.pathname.includes('/login') || window.location.pathname.includes('/claim-account')
})
const isActive = computed (()=>{
  return voucher_banner_active.value && !isInAccount.value && !isInGifts.value
})
const voucher_message = computed (()=>{
  var message = isVoucherEmpty.value ? getVoucherMessage(voucher_store.value.voucher) : getVoucherMessage(voucher.value)
  if(typeof message === 'object'){
    if(window.location.pathname.includes('/checkout'))
      message = message.checkout.replace('</strong>', '</strong> <br class="mobile-xs">')
    else
      message = message.pre_checkout.replace('</strong>', '</strong> <br class="mobile-xs">')
  }
  return message
})
// Methods
function compileMD() {
  return marked.parse(voucher_message.value)
}

function getVoucherMessage(voucher){
  if(voucher.green_banner){
    message_type.value = 'green'
    return voucher.green_banner
  }else{
    message_type.value = 'red'
    return voucher.red_banner_message
  }
}

// Watchers
watch(isActive, (newV) => {
  setTimeout(function () {
    if (isActive.value){
      var hero = document.getElementById('hero-banner')
      var wrapper = document.getElementById('page-wrapper')
      var bannerText =  document.getElementById('sitewide-banner-text-mobile') || document.getElementById('sitewide-banner-text-desktop')

      if(hero && isMobileSm.value ){
        hero.classList.add('sitewide-adjust')
      }
      if(wrapper && bannerText === null){
        wrapper.classList.add('voucher-banner-adjust')
      }
    }
  }, 500)
})
// Lifecycle Hooks
onMounted(() => {

  setTimeout(function () {
    if (isActive.value){
      var hero = document.getElementById('hero-banner')
      var wrapper = document.getElementById('page-wrapper')
      var bannerText =  document.getElementById('sitewide-banner-text-mobile') || document.getElementById('sitewide-banner-text-desktop')

      if(hero && isMobileSm.value ){
        hero.classList.add('sitewide-adjust')
      }
      if(wrapper && bannerText === null){
        wrapper.classList.add('voucher-banner-adjust')
      }
    }
  }, 500)
})
// Expose (defineExpose)
</script>
<script>
export default {
  name: 'VoucherNotificationBanner'
}
</script>
<style lang="scss">
#voucher-notification-banner,
#voucher-banner
{
  position: sticky;
  top: 60px;
  left: 0px;
  &.green{
    background-color: #CBEFE9;
  }

  &.red{
    background-color: #c41d1d;
    &.voucher-notification-banner,
    .voucher-notification-banner
    {
      .banner-message{
        p{
          color: #ffffff;
          a{
            color: #ffffff;
            text-decoration: underline;

            &:hover{
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  // z-index: 52

  @media (min-width: 576px) {
    top: 80px;
  }

  &.voucher-notification-banner,
  .voucher-notification-banner
  {
    height: 60px;
    padding: 0 20px;

    @media (min-width: 576px) {
      height: 40px;
    }

    .banner-message{
      text-align: center;
      p{
        color: #212427;
        font-family: 'Apercu';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0px;
        margin-bottom: 0px;
      }
    }
  }
}

</style>